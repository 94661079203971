import classnames from "classnames";
import { FC, useCallback, useState } from "react";
import styles from "./Frame.module.scss";

type FrameProps = {};

export const Frame: FC<FrameProps> = (props) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const MENU = [
    {
      index: 0,
      name: "Описание",
    },
    {
      index: 1,
      name: "Таймлайн",
    },
    {
      index: 2,
      name: "Плейлист",
    },
  ];

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case 0:
        return (
          <div className={styles["desc-content"]}>
            <p>
              Ох... что тебя сегодня ждёт - самый мясной тряс! <br />
              <br />И для начала мы к нему подготовимся с помощью разминки.
              <br />
              Приседай в плие с прямой спиной, таз чуть подкручен в себя Для начала попробуй просто
              поприседать. А теперь к приседу добавь движение крестцом - колени работают вверх и
              вниз, крестец «сбрасываю» вниз, а не вперед, и затем колени разгибаются (не
              полностью), а крестец уходит наверх. (то есть у нас получается две точки)
            </p>
          </div>
        );
      case 1:
        return (
          <div className={styles["timeline-wrapper"]}>
            <span>
              00:00 – 04.08 &nbsp;<p>– приветствие, разминка</p>
            </span>
            <span>
              04.08 – 05.32 &nbsp;<p>– повторение базового шейка</p>
            </span>
            <span>
              05.32 – 06.56 &nbsp;<p>– подготовка, плие</p>
            </span>
            <span>
              06.56 – 09.53 &nbsp;<p>– добавляем работу крестца</p>
            </span>
          </div>
        );
      case 2:
        return (
          <div className={styles["playlist-wrapper"]}>
            <p>1. Трек Secrets (feat. Vassy) [Diplo Remix]. Исполнитель Tiësto & KSHMR</p>
            <p>2. Play. Давид Банер</p>
            <p>3. Ball If I Want To. DaBaby</p>
            <p>4. No Time (feat. Lil Durk). KSI</p>
            <p>5. Big Subwoofer. MOUNT WESTMORE, Snoop Dogg, Ice Cube, E-40 & Too $hort</p>
            <p>6. Sharing Locations (feat. Lil Baby & Lil Durk). Meek Mill.</p>
            <p>7. Have Mercy. Chlöe.</p>
            <p>8. Get Into It (Yuh). Doja Cat.</p>
            <p>9. ili. Troyboi.</p>
          </div>
        );
    }
  }, [activeTab]);

  return (
    <div className={styles["frame-wrapper"]}>
      <div className={styles["video-responsive"]}>
        <iframe
          src="//playercdn.cdnvideo.ru/aloha/players/basic_player1.html?account=6jkj53enk6&source=https://mulq286xqj.a.trbcdn.net/Hip.mp4/playlist.m3u8"
          frameBorder="0"
          scrolling="no"
          style={{ overflow: "hidden" }}
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles["frame-content"]}>
        <span className={styles["frame-content-header"]}>Урок 5. Шейк в плие</span>
        <div className={styles["frame-content-menu"]}>
          {MENU.map((el) => (
            <div
              className={classnames(
                styles["frame-content-menu-elem"],
                el.index === activeTab
                  ? styles["frame-content-menu-active-elem"]
                  : styles["frame-content-menu-disable-elem"]
              )}
              onClick={() => setActiveTab(el.index)}
            >
              {el.name}
            </div>
          ))}
        </div>
        <div>{renderTab()}</div>
      </div>
    </div>
  );
};
