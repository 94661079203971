import React from "react";

 const SliderIconLeft = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0H6V2H8V0Z" fill="#686868" />
      <path d="M4 4H6V2H4V4Z" fill="#686868" />
      <path d="M4 10V8H15V6H4V4H2V6H0V8H2V10H4Z" fill="#686868" />
      <path d="M6 12H4V10H6V12Z" fill="#686868" />
      <path d="M6 12V14H8V12H6Z" fill="#686868" />
    </svg>
  );
};

export default SliderIconLeft