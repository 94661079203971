export const ArrRight = () => {

    return (<svg width="16" height="14" viewBox="0 0 16 14" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 0H9.5V2H7.5V0Z" fill="white" />
        <path d="M11.5 4H9.5V2H11.5V4Z" fill="white" />
        <path d="M11.5 10V8H0.5V6H11.5V4H13.5V6H15.5V8H13.5V10H11.5Z" fill="white" />
        <path d="M9.5 12H11.5V10H9.5V12Z" fill="white" />
        <path d="M9.5 12V14H7.5V12H9.5Z" fill="white" />
    </svg>)
}

