import { MENU } from "constants/menu";
import { memo, useCallback, useState } from "react";
import styles from "./Menu.module.scss";
import classNames from "classnames";
import { FrameworkImagesAnimation } from "components/common/FrameworkImagesAnimation/FrameworkImagesAnimation";

const MenuLayout = () => {
  const [activeTab, setActiveTab] = useState<string>("Backend");


  const renderAnumation = useCallback(() => {
    return (
      <FrameworkImagesAnimation activeTab={activeTab} />
    )
  }, [activeTab])

  return (
    <div className={styles["menu-layout"]}>
      <div className={classNames(styles["menu-layout-column"], styles["menu-layout-column-left"])}>
        <div className={styles["menu-layout-desctop"]}>
          <p>
            <i>/*</i>your{" "}
          </p>
          <p>development</p>
          <p>team is ready</p>
          <p>
            to get started<i>*/</i>
          </p>
        </div>
        <div className={styles["menu-layout-mobile"]}>
          <p>
            <i>/*</i>your development team is ready to get started<i>*/</i>
          </p>
        </div>
      </div>

      <div
        className={classNames(styles["menu-layout-column"], styles["menu-layout-column-center"])}
      >
        <div className={styles["menu-list"]}>
          {MENU.map((el) => (
            <p
              key={el.name}
              className={classNames(
                styles["menu-item"],
                (activeTab === el.name && styles["active-item"]) || ""
              )}
              onClick={() => setActiveTab(el.name)}
            >
              {el.name}
            </p>
          ))}
        </div>
      </div>
      {renderAnumation()}
    </div>
  );
};

export default memo(MenuLayout);
