import { FC } from "react";
import { TFeedback } from "typings/feedback";
import ClienFeedbaksLayout from "./ClientFeedbacks.layout";

type ClientFeedbaksProps = {
  feedbaksList: TFeedback[];
};

const ClientFeedbaks: FC<ClientFeedbaksProps> = ({ feedbaksList }) => {
  return <ClienFeedbaksLayout feedbaksList={feedbaksList} />;
};

export default ClientFeedbaks;
