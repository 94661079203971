import { FC } from "react";
import { TFeedback } from "typings/feedback";
import styles from "./ClientFeedbakItem.module.scss";

type ClientFeedbakItemProps = {
  feedback: TFeedback;
};

const ClientFeedbakItem: FC<ClientFeedbakItemProps> = ({ feedback }) => {
  return (
    <div className={styles["client-feedbak-wrapper"]}>
      <span className={styles["client-feedbak-wrapper-text"]}>{feedback.text}</span>
      <div className={styles["client-feedbak-wrapper-project"]}>
        <img src={feedback.projectIcon} alt={`${feedback.project}-logo`} />
        <p>{feedback.project}</p>
      </div>
      <span className={styles["client-feedbak-wrapper-desc"]}>{feedback.desc}</span>
    </div>
  );
};
export default ClientFeedbakItem;
