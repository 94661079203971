import classNames from "classnames";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { memo } from "react";
import styles from "./Recruit.module.scss";
import arrImg from "../../assets/images/arr-right.svg";
import dashImg from "../../assets/images/dash.svg";
import { useNavigate } from "react-router-dom";
import { FEEDBACKS_LIST } from "constants/feedbacks-list";
import ClientFeedbaks from "components/ClientFeedbaks/ClientFeedbacks";
import HireWithUs from "components/HireWithUs/HireWithUs";

const RecruitLayout = () => {
  const navigate = useNavigate();

  return (
    <div className={styles["main-layout"]}>
      <div className={styles["main-layout-content"]}>
        <Header />
        <div className={styles["desc-wrap"]}>
          <p>
            We know what developers you need ‘cause we are <i>the devs</i>
          </p>
        </div>
        <div className={styles["stat-wrap"]}>
          <div className={styles["stat-wrap-content"]}>
            <div className={styles["stat-wrap-col"]}>
              <span className={classNames(styles["stat-wrap-col-header"], styles["green-text"])}>
                2k+
              </span>
              <p>Specialists reviewed in 2022</p>
            </div>
            <div className={styles["stat-wrap-col"]}>
              <span className={styles["stat-wrap-col-header"]}>60%</span>
              <p>Candidates accept offers</p>
            </div>
            <div className={styles["stat-wrap-col"]}>
              <span className={styles["stat-wrap-col-header"]}>
                3 <p className={styles["stat-wrap-col-pre-header"]}>weeks</p>
              </span>
              <p>Avg. time to hire</p>
            </div>
          </div>
        </div>
        <div className={styles["happy-clients"]}>
          <span>Happy clients</span>
          <div className={styles["happy-clients-projects-list"]}>
            <div className={styles["happy-clients-projects-list-group"]}>
              <p>Neon</p>
              <p>Zabota</p>
              <p>Imigrata</p>
            </div>
            <div className={styles["happy-clients-projects-list-group"]}>
              <p>Human Capital Company</p>
            </div>
          </div>
        </div>
        <div className={styles["photos-wrapper"]}>
          <div className={classNames(styles["photos-col"], styles["vlad-frame"])}>
            <img src={"./assets/vlad-frame.png"} alt="vlad-frame" />
            <span>Vlad</span>
            <div className={styles["photos-col-group"]}>
              <img src={dashImg} alt="dash" />
              <p>Founder</p>
            </div>
          </div>
          
        </div>
        <div className={styles["photos-hire-with-us"]}>
          <div className={styles["photos-hire-with-us-content"]}>
            <p>
              <i>/*</i>your new employee is about to be hired<i>*/</i>
            </p>
            <button onClick={() => navigate("/contact-us-recruit")}>
              Hire with us <img src={arrImg} alt="dash" />
            </button>
          </div>
        </div>
        <ClientFeedbaks feedbaksList={FEEDBACKS_LIST} />
        <HireWithUs />
        <Footer />
      </div>
    </div>
  );
};

export default memo(RecruitLayout);
