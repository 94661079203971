import { sendEmail } from "api/email";
import { memo, useEffect, useState } from "react";
import ContactUsLayout from "./ContactUs.layout";

const ContactUs = () => {

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleSubmit = (state: boolean) => setIsSubmit(state)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [])

  const sendFeedback = async (data: { email: string; comment: string }) => {
    try {

      const response = await sendEmail(data)
      if (response.status === 200 || response.status === 201) {
        setIsSubmit(true)
      } else throw new Error()
    }
    catch (err: any) {
    }
  }

  return <ContactUsLayout sendFeedback={sendFeedback} isSubmit={isSubmit} handleSubmit={handleSubmit} />;
};

export default memo(ContactUs);
