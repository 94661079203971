import { useEffect, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import { clearMessage } from "./slices/toastSlice";
import { ToastState } from "./typings/toast";

import MainContentLayout from "./components/common/MainContentLayout/MainContentLayout";

import BackendPage from "./pages/Backend/Backend";
import ContactUsPage from "./pages/ContactUs/ContactUs";
import RecruitPage from "./pages/Recruit/Recruit";
import ContactUsRecruitPage from "./pages/ContactUsRecruit/ContactUsRecruit";
import FramePage from './pages/Frame/Frame';

function App() {
  const toastRef = useRef(null);
  const dispatch = useAppDispatch();
  const toastState: ToastState = useAppSelector((state) => state.toast);

  useEffect(() => {
    if (!toastRef) return;
    handleMessage(toastState);
  }, [toastState]);

  const handleMessage = (toastState: ToastState) => {
    if (toastState.summary.length > 1) {
      //@ts-ignore
      toastRef.current.show({
        severity: toastState.severity,
        summary: toastState.summary,
        detail: toastState.detail,
      });
    }
  };
  return (
    <>
      <Toast ref={toastRef} onHide={() => dispatch(clearMessage())} />

      <Routes>
        <Route path={"/"} element={<Navigate to="/develop" />} />
        <Route
          path={"/develop"}
          element={
            <MainContentLayout>
              <BackendPage />
            </MainContentLayout>
          }
        />
        <Route path={"/recruit"} element={<RecruitPage />} />
        <Route path={"/frame"} element={<FramePage />} />
        <Route path={"/contact-us"} element={<ContactUsPage />} />
        <Route path={"/contact-us-recruit"} element={<ContactUsRecruitPage />} />
      </Routes>
    </>
  );
}

export default App;
