export const MENU = [
  {
    name: "Backend",
    link: "/back-end",
  },
  {
    name: "Frontend",
    link: "/front-end",
  },
  {
    name: "Devops",
    link: "devops",
  },
  {
    name: "QA",
    link: "/qa",
  },
  {
    name: "Design",
    link: "/design",
  },
];
