export const PROJECTS = [
  {
    title: "Livius",
    desc: "Service for the elderly, where their health status is monitored over wireless networks",
    tags: "#healthcare #hardware #backend #nodejs #frontend #react  #kubernetes #docker #mqtt",
    link: "https://livius.com.au/",
  },
  {
    title: "PeopleKnow",
    desc: "Employee and customer feedback service for businesses of all sizes",
    tags: "#hr #backend #.net #docker",
    link: "https://peopleknow.net/",
  },
  {
    title: "Imigrata",
    desc: "Automating immigration processes to help you relocate to a new country for work, study or life",
    tags: "#backend #nodejs #frontend #react #flutter #docker",
    link: "https://imigrata.com/",
  },
  {
    title: "S-Crypt",
    desc: "Crypto AML application for B2B and B2C clients to make work with crypto safe and clear",
    tags: "#cryptoaml #backend #nodejs #frontend #react #telegrambot #docker",
    link: "https://s-crypt.ru/",
  },
  {
    title: "Talent Culture",
    desc: "HR tech product to allow companies measure and analyze employee engagement ",
    tags: "#hr #backend #.net #angular #ml #nlp",
    link: "https://www.humancapitalcompany.com/",
  },
  {
    title: "SeshMe",
    desc: "SeshMe is a Cross platform mobile application for people to connect with other fitness enthusiasts and professional trainers for their perfect workout",
    tags: "#sport #mobile #reactnative #.net",
    link: "https://seshmefitness.com.au/",
  },
  {
    title: "Zabota",
    desc: "Web and mailing system for the medical marketing automation system, increasing the flow of patients and their loyalty.",
    tags: "#marketing #backend #nodejs #.net #frontend #react",
    link: "https://zabota.tech/",
  },
  {
    title: "Aimfly",
    desc: "Cross platform app for career coaching",
    tags: "#edtech #frontend #flutter #backend #nodejs #docker",
    link: "https://aimfly.io/",
  },
];
