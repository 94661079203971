import React from "react";

const SliderIconRight = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 0H9V2H7V0Z" fill="white" />
      <path d="M11 4H9V2H11V4Z" fill="white" />
      <path d="M11 10V8H0V6H11V4H13V6H15V8H13V10H11Z" fill="white" />
      <path d="M9 12H11V10H9V12Z" fill="white" />
      <path d="M9 12V14H7V12H9Z" fill="white" />
    </svg>
  );
};

export default SliderIconRight;
