import { memo } from "react";
import styles from "./Header.module.scss";
import mascotImg from "../../assets/images/mascot.svg";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Logo } from "assets/images/cherrypickLogo";

const HeaderLayout = () => {
  const navigate = useNavigate();
  const url = window.location.href;

  return (
    <div className={styles["header-layout"]}>
      <div className={styles["header-layout-top-container"]}>
        <div className={styles["logo-wrapper"]} onClick={() => navigate("/")}>
          <Logo />
        </div>
        <button
          className={styles["contact-us-btn"]}
          onClick={() => navigate(url.includes("/recruit") ? "/contact-us-recruit" : "/contact-us")}
        >
          {url.includes("/recruit") ? "Hire with us" : "Contact Us"}
        </button>
        <span className={styles["header-layout-bottom-border"]}>
          ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        </span>
      </div>
      <div className={styles["header-layout-bottom-container"]}>
        <div
          className={classNames(
            styles["header-layout-bottom-content-container"],
            styles["hide-container"]
          )}
        >
          <img src={mascotImg} alt="logo" style={{ width: 195, height: 156 }} />
        </div>
        {/*<div className={styles["header-layout-bottom-content-container"]}>*/}
          {/*<span*/}
          {/*  className={*/}
          {/*    !url.includes("/recruit") ? "" : styles["gray-text"] && styles["disabled-item"]*/}
          {/*  }*/}
          {/*  onClick={() => navigate("/")}*/}
          {/*>*/}
          {/*  Develop*/}
          {/*</span>*/}
          {/*<span className={styles["green-text"]}>/</span>*/}
          {/*<span*/}
          {/*  className={url.includes("/recruit") ? "" : styles["disabled-item"]}*/}
          {/*  onClick={() => navigate("/recruit")}*/}
          {/*>*/}
          {/*  Recruit*/}
          {/*</span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default memo(HeaderLayout);
