import axios from "axios";
import { API_URL } from "../constants/config";

const http = axios.create({
  baseURL: API_URL,
});

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};
const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

export default {
  http,
  get,
  post,
  put,
  remove,
  patch,
};
