import styles from "../../components/Header/Header.module.scss";

export const Logo = () => {
  return (
    <svg
      width="149"
      height="40"
      viewBox="0 0 149 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles["svg-hover"]}
        d="M13.9148 19.9734H9.82736C9.79837 19.6352 9.72107 19.3284 9.59545 19.053C9.47466 18.7776 9.30555 18.5408 9.08813 18.3427C8.87555 18.1398 8.61706 17.9852 8.31267 17.8789C8.00828 17.7678 7.66283 17.7122 7.2763 17.7122C6.59989 17.7122 6.02735 17.8765 5.55869 18.205C5.09486 18.5336 4.74216 19.0047 4.50058 19.6183C4.26384 20.2319 4.14546 20.9687 4.14546 21.8287C4.14546 22.737 4.26625 23.498 4.50783 24.1116C4.75424 24.7204 5.10936 25.1794 5.57319 25.4886C6.03701 25.793 6.59506 25.9452 7.24732 25.9452C7.61934 25.9452 7.95272 25.8993 8.24745 25.8075C8.54217 25.7108 8.79824 25.5732 9.01566 25.3944C9.23308 25.2156 9.40943 25.0006 9.54471 24.7494C9.68483 24.4933 9.77904 24.2058 9.82736 23.8869L13.9148 23.9159C13.8665 24.544 13.6902 25.1842 13.3858 25.8365C13.0814 26.4839 12.649 27.083 12.0885 27.6338C11.5329 28.1798 10.8444 28.6194 10.023 28.9528C9.20167 29.2862 8.24745 29.4529 7.16035 29.4529C5.79785 29.4529 4.57547 29.1606 3.49321 28.576C2.41577 27.9913 1.563 27.1313 0.934904 25.9959C0.311635 24.8605 0 23.4714 0 21.8287C0 20.1763 0.318882 18.7848 0.956646 17.6543C1.59441 16.5188 2.45442 15.6612 3.53669 15.0815C4.61896 14.4968 5.82684 14.2045 7.16035 14.2045C8.09767 14.2045 8.9601 14.3326 9.74764 14.5886C10.5352 14.8447 11.2261 15.2192 11.8204 15.712C12.4147 16.2 12.893 16.8015 13.2553 17.5166C13.6177 18.2316 13.8375 19.0506 13.9148 19.9734Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M15.743 29.25V14.4075H19.7725V20.2053H25.1065V14.4075H29.136V29.25H25.1065V23.4521H19.7725V29.25H15.743Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M31.0493 29.25V14.4075H41.7463V17.6543H35.0788V20.2053H41.1956V23.4521H35.0788V26.0032H41.7174V29.25H31.0493Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M43.7448 29.25V14.4075H50.1514C51.253 14.4075 52.2169 14.608 53.0431 15.009C53.8693 15.41 54.5119 15.9874 54.9709 16.7411C55.4299 17.4948 55.6594 18.3983 55.6594 19.4516C55.6594 20.5145 55.4226 21.4108 54.9491 22.1403C54.4805 22.8699 53.821 23.4207 52.9706 23.7927C52.1251 24.1648 51.1371 24.3508 50.0065 24.3508H46.1799V21.2199H49.1948C49.6683 21.2199 50.0717 21.162 50.4051 21.046C50.7433 20.9252 51.0018 20.7344 51.1805 20.4735C51.3641 20.2126 51.4559 19.8719 51.4559 19.4516C51.4559 19.0264 51.3641 18.681 51.1805 18.4152C51.0018 18.1447 50.7433 17.9466 50.4051 17.8209C50.0717 17.6905 49.6683 17.6253 49.1948 17.6253H47.7743V29.25H43.7448ZM52.4416 22.4375L56.1522 29.25H51.7748L48.1512 22.4375H52.4416Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M57.377 29.25V14.4075H63.7836C64.8852 14.4075 65.8491 14.608 66.6753 15.009C67.5015 15.41 68.1441 15.9874 68.6031 16.7411C69.0621 17.4948 69.2916 18.3983 69.2916 19.4516C69.2916 20.5145 69.0548 21.4108 68.5813 22.1403C68.1127 22.8699 67.4532 23.4207 66.6028 23.7927C65.7573 24.1648 64.7693 24.3508 63.6387 24.3508H59.8121V21.2199H62.827C63.3005 21.2199 63.7039 21.162 64.0373 21.046C64.3755 20.9252 64.634 20.7344 64.8127 20.4735C64.9963 20.2126 65.0881 19.8719 65.0881 19.4516C65.0881 19.0264 64.9963 18.681 64.8127 18.4152C64.634 18.1447 64.3755 17.9466 64.0373 17.8209C63.7039 17.6905 63.3005 17.6253 62.827 17.6253H61.4065V29.25H57.377ZM66.0738 22.4375L69.7844 29.25H65.407L61.7834 22.4375H66.0738Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M69.7029 14.4075H74.1962L77.0951 20.4372H77.2111L80.11 14.4075H84.6033L79.1534 24.5827V29.25H75.1528V24.5827L69.7029 14.4075Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M89.9827 29.25V14.4075H96.3893C97.4909 14.4075 98.4548 14.6249 99.281 15.0597C100.107 15.4946 100.75 16.1057 101.209 16.8933C101.668 17.6808 101.897 18.6012 101.897 19.6545C101.897 20.7174 101.661 21.6379 101.187 22.4157C100.718 23.1936 100.059 23.7927 99.2085 24.2131C98.363 24.6334 97.3749 24.8436 96.2443 24.8436H92.4178V21.7127H95.4326C95.9061 21.7127 96.3096 21.6306 96.6429 21.4663C96.9812 21.2972 97.2396 21.0581 97.4184 20.7489C97.602 20.4396 97.6938 20.0749 97.6938 19.6545C97.6938 19.2293 97.602 18.867 97.4184 18.5674C97.2396 18.263 96.9812 18.0311 96.6429 17.8717C96.3096 17.7074 95.9061 17.6253 95.4326 17.6253H94.0122V29.25H89.9827Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M107.405 14.4075V29.25H103.376V14.4075H107.405Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M123.172 19.9734H119.084C119.055 19.6352 118.978 19.3284 118.852 19.053C118.732 18.7776 118.562 18.5408 118.345 18.3427C118.132 18.1398 117.874 17.9852 117.57 17.8789C117.265 17.7678 116.92 17.7122 116.533 17.7122C115.857 17.7122 115.284 17.8765 114.816 18.205C114.352 18.5336 113.999 19.0047 113.757 19.6183C113.521 20.2319 113.402 20.9687 113.402 21.8287C113.402 22.737 113.523 23.498 113.765 24.1116C114.011 24.7204 114.366 25.1794 114.83 25.4886C115.294 25.793 115.852 25.9452 116.504 25.9452C116.876 25.9452 117.21 25.8993 117.504 25.8075C117.799 25.7108 118.055 25.5732 118.273 25.3944C118.49 25.2156 118.666 25.0006 118.802 24.7494C118.942 24.4933 119.036 24.2058 119.084 23.8869L123.172 23.9159C123.123 24.544 122.947 25.1842 122.643 25.8365C122.338 26.4839 121.906 27.083 121.345 27.6338C120.79 28.1798 120.101 28.6194 119.28 28.9528C118.459 29.2862 117.504 29.4529 116.417 29.4529C115.055 29.4529 113.832 29.1606 112.75 28.576C111.673 27.9913 110.82 27.1313 110.192 25.9959C109.569 24.8605 109.257 23.4714 109.257 21.8287C109.257 20.1763 109.576 18.7848 110.214 17.6543C110.851 16.5188 111.711 15.6612 112.794 15.0815C113.876 14.4968 115.084 14.2045 116.417 14.2045C117.355 14.2045 118.217 14.3326 119.005 14.5886C119.792 14.8447 120.483 15.2192 121.077 15.712C121.672 16.2 122.15 16.8015 122.512 17.5166C122.875 18.2316 123.094 19.0506 123.172 19.9734Z"
        fill="white"
      />
      <path
        className={styles["svg-hover"]}
        d="M125 29.25V14.4075H129.029V20.4082H129.232L133.697 14.4075H138.393L133.378 21.017L138.509 29.25H133.697L130.363 23.684L129.029 25.4234V29.25H125Z"
        fill="white"
      />
      <path
        d="M144.959 30.1831C144.084 30.1831 143.332 29.8774 142.706 29.2659C142.087 28.6468 141.781 27.8957 141.789 27.0124C141.781 26.1518 142.087 25.4157 142.706 24.8042C143.332 24.1927 144.084 23.887 144.959 23.887C145.79 23.887 146.522 24.1927 147.156 24.8042C147.798 25.4157 148.123 26.1518 148.13 27.0124C148.123 27.6012 147.968 28.1372 147.666 28.6204C147.371 29.096 146.986 29.4772 146.511 29.7641C146.035 30.0434 145.518 30.1831 144.959 30.1831Z"
        fill="#B30018"
      />
    </svg>
  );
};
