import SliderIconLeft from "assets/images/icon-left";
import SliderIconRight from "assets/images/icon-right";
import ClientFeedbakItem from "components/ClientFeedbakItem/ClientFeedbakItem";
import { FC } from "react";
import { TFeedback } from "typings/feedback";
import styles from "./ClientFeedbaks.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type ClienFeedbaksLayoutProps = {
  feedbaksList: TFeedback[];
};

const ClienFeedbaksLayout: FC<ClienFeedbaksLayoutProps> = ({ feedbaksList }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return (
      <div className={styles["client-feedbaks-layout-slider-swither"]}>
        <span
          className={currentSlide === 0 ? styles["disable-switch"] : styles["active-switch"]}
          onClick={() => currentSlide !== 0 && goToSlide(currentSlide - 1)}
        >
          <SliderIconLeft />
        </span>
        <span
          className={
            currentSlide > feedbaksList.length - 3
              ? styles["disable-switch"]
              : styles["active-switch"]
          }
          onClick={() => currentSlide <= feedbaksList.length - 3 && goToSlide(currentSlide + 1)}
        >
          <SliderIconRight />
        </span>
      </div>
    );
  };

  return (
    <div className={styles["client-feedbaks-layout"]}>
      <div className={styles["client-feedbaks-layout-title"]}>
        <span>Client feedbacks</span>
      </div>
      <div className={styles["client-feedbaks-layout-list"]}>
        <Carousel
          responsive={responsive}
          arrows={false}
          renderButtonGroupOutside={true}
          //@ts-ignore
          customButtonGroup={<ButtonGroup />}
        >
          {feedbaksList.map((el, index) => (
            <ClientFeedbakItem feedback={el} key={el.text + index} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ClienFeedbaksLayout;
