import React, {useEffect, useState} from 'react';
import TypingEffect from "./TypingEffect";

const SloganTyping: React.FC<{ slogan: { text: string; color: string }[] }> = ({slogan,}) => {
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    if (wordIndex < slogan.length) {
      const timeout = setTimeout(() => {
        setWordIndex((prevIndex) => prevIndex + 1);
      }, slogan[wordIndex].text.length * 100);

      return () => clearTimeout(timeout);
    }
  }, [wordIndex, slogan]);
  return (
    <p>
      {slogan.slice(0, wordIndex).map((sl) => (
        <TypingEffect text={sl.text} delay={70} color={sl.color} key={sl.text.toString()} />
      ))}
    </p>
  );
};

export default SloganTyping;