import tallentLogo from "../assets/images/talentswot_logo.svg";
import synergyLogo from "../assets/images/synergy_logo.svg";
import seshmeLogo from "../assets/images/seshme_logo.svg";

export const FEEDBACKS_LIST = [
  {
    text: `The Devs get the strategy, the commercial, and the technical visions in a way that no other firm has been able to show. It's wonderful to see how vested The Devs team is in Talentswot success.”`,
    project: "TalentSwot",
    projectIcon: tallentLogo,
    desc: "HR platform for initial screening, personality analysis and shortlisting candidates via chatbot",
  },
  {
    text: `The team made everything from requirements to designs and implemented exactly what we want! Our project was a POC and we wanted to go to the market as quick as possible. The Devs gave us this chance and from the moment that we started working with them to the moment that we started selling took 3 months! Great job!”`,
    project: "Synergy",
    projectIcon: synergyLogo,
    desc: "Mobile web platform for users of sport clubs looking for perfect insurance",
  },
  {
    text: `Software development isnвЂ™t just a career for The Devs but a passion to which they strive to completely understand our business objectives, offer their expert opinion and strive to deliver above and beyond our expectations.”`,
    project: "SeshMe",
    projectIcon: seshmeLogo,
    desc: "Cross-platform mobile application to find your perfect fitness session with professional trainers",
  },
  {
    text: `The Devs get the strategy, the commercial, and the technical visions in a way that no other firm has been able to show. It's wonderful to see how vested The Devs team is in Talentswot success.”`,
    project: "TalentSwot",
    projectIcon: tallentLogo,
    desc: "HR platform for initial screening, personality analysis and shortlisting candidates via chatbot",
  },
  {
    text: `The team made everything from requirements to designs and implemented exactly what we want! Our project was a POC and we wanted to go to the market as quick as possible. The Devs gave us this chance and from the moment that we started working with them to the moment that we started selling took 3 months! Great job!”`,
    project: "Synergy",
    projectIcon: synergyLogo,
    desc: "Mobile web platform for users of sport clubs looking for perfect insurance",
  },
  {
    text: `Software development isnвЂ™t just a career for The Devs but a passion to which they strive to completely understand our business objectives, offer their expert opinion and strive to deliver above and beyond our expectations.”`,
    project: "SeshMe",
    projectIcon: seshmeLogo,
    desc: "Cross-platform mobile application to find your perfect fitness session with professional trainers",
  },
  {
    text: `The Devs get the strategy, the commercial, and the technical visions in a way that no other firm has been able to show. It's wonderful to see how vested The Devs team is in Talentswot success.”`,
    project: "TalentSwot",
    projectIcon: tallentLogo,
    desc: "HR platform for initial screening, personality analysis and shortlisting candidates via chatbot",
  },
  {
    text: `The team made everything from requirements to designs and implemented exactly what we want! Our project was a POC and we wanted to go to the market as quick as possible. The Devs gave us this chance and from the moment that we started working with them to the moment that we started selling took 3 months! Great job!”`,
    project: "Synergy",
    projectIcon: synergyLogo,
    desc: "Mobile web platform for users of sport clubs looking for perfect insurance",
  },
  {
    text: `Software development isnвЂ™t just a career for The Devs but a passion to which they strive to completely understand our business objectives, offer their expert opinion and strive to deliver above and beyond our expectations.”`,
    project: "SeshMe",
    projectIcon: seshmeLogo,
    desc: "Cross-platform mobile application to find your perfect fitness session with professional trainers",
  },
];
